<template>
  <div class="goodsAllWaybill">
    <div class="facilityBox">
      <el-form class="queryForm" :model="manageForm" ref="manageForm">
        <div style="display: flex; flex-wrap: wrap">
          <el-form-item label="业务类型" prop="businessType">
            <el-select
              v-model="manageForm.businessType"
              placeholder="请选择业务类型"
              @change="businessChange"
            >
              <el-option
                v-for="item in businessList"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="场景类型" prop="scenarioType">
            <el-select
              v-model="manageForm.scenarioType"
              placeholder="请选择场景类型"
              @change="sceneTypeChange"
            >
              <el-option
                v-for="item in sceneList"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发票类型" prop="invoiceType">
            <el-select
              v-model="manageForm.invoiceType"
              placeholder="请选择发票类型"
              @change="search"
            >
              <el-option
                v-for="item in invoiceList"
                :key="item.Code"
                :label="item.Name"
                :value="item.Code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="货主单位" prop="OwnerName">
            <el-input
              class="fromInp"
              v-model="manageForm.OwnerName"
              placeholder="请输入货主单位"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label-width="80px">
            <div style="display: flex; align-items: center">
              <el-button type="primary" @click="search()" icon="el-icon-search"
                >搜索
              </el-button>
              <el-button type="primary" @click="clearData()" icon="el-icon-delete"
                >清空
              </el-button>
              <el-button type="primary" @click="downloadBillList" icon="el-icon-download"
                >下载表格
              </el-button>
            </div>
          </el-form-item>
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <el-form-item label="托运人" prop="Shipper">
            <el-input
              class="fromInp"
              v-model="manageForm.Shipper"
              placeholder="请输入托运人姓名"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="承运人手机号" prop="ShipownerPhone">
            <el-input
              class="fromInp"
              v-model="manageForm.ShipownerPhone"
              placeholder="请输入承运人手机号"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="代收人手机号" prop="PayeePhone">
            <el-input
              class="fromInp"
              v-model="manageForm.PayeePhone"
              placeholder="请输入代收人手机号"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="船舶名称" prop="ShipName">
            <el-input
              class="fromInp"
              v-model="manageForm.ShipName"
              placeholder="请输入船舶名称"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="运单号：">
            <el-input
            style="width: 300px" 
              @keyup.enter.native="search"
              v-model="manageForm.WaybillId"
              placeholder="请输入运单号"
            >
              <el-select
                v-model="manageForm.waybillType"
                slot="prepend"
                placeholder="请选择单号类型"
                style="width: 100px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-input>
          </el-form-item>
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <!-- <el-form-item label="运单号" prop="bbid">
            <el-input
              class="fromInp"
              v-model="manageForm.bbid"
              placeholder="请输入运单号"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="运单编号" prop="Id">
            <el-input
              class="fromInp"
              v-model="manageForm.Id"
              placeholder="请输入运单编号"
              clearable
            >
            </el-input>
          </el-form-item> -->
          <el-form-item label="结算单号" prop="BSID">
            <el-input
              class="fromInp"
              v-model="manageForm.BSID"
              placeholder="请输入结算单号"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="开票状态">
            <el-select v-model="manageForm.status" placeholder="请选择开票状态">
              <el-option
                v-for="item in statusList"
                :key="item.Item2"
                :label="item.Item1"
                :value="item.Item2"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期筛选">
            <el-date-picker
              v-model="manageForm.searchDate"
              type="daterange"
              range-separator="至"
              start-placeholder="请选择开始时间"
              end-placeholder="请选择结束时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </el-form>
      <el-table
        :data="tableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="flag.importListLoading"
        ref="multipleTable"
        :key="manageForm.businessType"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
          fixed="left"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="BBID"
          label="运单号"
          width="200"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="Id"
          label="运单编号"
          width="200"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="BSID"
          label="结算单号"
          width="200"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="TaskTypeName"
          label="场景类型"
          width="150"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="OwnerName"
          label="货主单位"
          width="180"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="Shipper"
          label="托运人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerName"
          label="承运人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerIDCard"
          label="承运人身份证号"
          show-overflow-tooltip
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerPhone"
          label="承运人手机号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="PayeeName"
          label="代收人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="PayeeIDCard"
          label="代收人身份证号"
          show-overflow-tooltip
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="PayeePhone"
          label="代收人手机号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipName"
          label="船舶名称"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="GoodsName"
          label="货物名称"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="GoodsUnitName"
          label="货物单位"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="OriginTime"
          label="起始时间"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="OriginAddress"
          label="起始地"
          show-overflow-tooltip
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <div @click="openMap(scope.row)">
              <el-link type="primary">{{ scope.row.OriginAddress }}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="ArriveTime"
          label="到达时间"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ArriveAddress"
          label="到达地"
          show-overflow-tooltip
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <div @click="openMap(scope.row)">
              <el-link type="primary">{{ scope.row.OriginAddress }}</el-link>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="Counts"
          label="货物数量"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="Price"
          label="运输单价"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{ scope.row.Price | formatMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="CarriageFee"
          label="运费"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17" v-if="manageForm.businessType.includes('DK')">{{
              scope.row.CarriageFee | formatMoney
            }}</span>
            <span style="color: #ff8b17" v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="SupplierProfit"
          label="服务费/税费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="!manageForm.businessType.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17" v-if="scope.row.ApplicantUserID == userId">{{
              scope.row.ExpensesOfTaxation | formatMoney
            }}</span>
            <span style="color: #ff8b17" v-else>{{
              scope.row.SupplierProfit | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalAmount"
          label="运单总金额"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="!manageForm.businessType.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{ scope.row.TotalAmount | formatMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TrustFee"
          label="委托代开运费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="manageForm.businessType.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">/</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="SupplierProfit"
          label="委托代开服务费/税费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="manageForm.businessType.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17" v-if="scope.row.ApplicantUserID == userId">{{
              scope.row.ExpensesOfTaxation | formatMoney
            }}</span>
            <span style="color: #ff8b17" v-else>{{
              scope.row.SupplierProfit | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalAmount"
          label="委托代开运单总金额"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="manageForm.businessType.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{ scope.row.TotalAmount | formatMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ThirdBillID"
          label="客户单号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="StatusName"
          label="开票状态"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="CreateTime"
          label="创建时间"
          show-overflow-tooltip
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="PaymentDate"
          label="付款时间"
          show-overflow-tooltip
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
          width="150"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="openVoucherDialog(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin: 10px 0 0 0">
        <!-- 分页 -->
        <el-pagination
          class="pagination"
          background
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 上传凭证dialog -->
    <el-dialog
      width="1200px"
      title="凭证"
      center
      :visible.sync="flag.voucherDialog"
      append-to-body
      v-if="flag.voucherDialog"
      :close-on-click-modal="false"
    >
      <EvidenceInfo :businessID="currentItem.BBID"> </EvidenceInfo>
    </el-dialog>

    <el-dialog
      width="800px"
      :close-on-click-modal="false" 
      :visible.sync="traceDialog"
      append-to-body
      class="deep_dialog"
    >
      <span slot="title">查看地图</span>
      <!-- 地图组件 -->
      <TXmap style="height: 600px" v-if="traceDialog" ref="txMap" :list="mapList">
      </TXmap>
    </el-dialog>
  </div>
</template>
<script>
import TXmap from "@/components/commonCmpt/TXmap";
import {
  GetBTaskType,
  BillAll,
  DownloadBillAll,
  GetBillStatusList,
  getMapPath,
} from "@/api/purchaseManage/invoiceFlow";
import { _getUserId } from "@/utils/storage";
import EvidenceInfo from "@/components/businessCmpt/evidenceInfo";
export default {
  data() {
    return {
      options: [
        {
          value: 0,
          label: '运单号',
        },
        {
          value: 1,
          label: '客户单号',
        },
        {
          value: 2,
          label: '运单编号',
        },
      ],
      manageForm: {
        WaybillId: '',
        waybillType: 0, //单号类型
        bbid: "",
        Id: null,
        BatchID: "",
        OwnerName: "",
        Shipper: "",
        ShipName: "",
        ShipownerPhone: "",
        PayeePhone: "",
        status: "",
        searchDate: ["", ""],
        businessType: "", // 业务类型
        scenarioType: "", // 场景类型
        invoiceType: "", // 发票类型
      },
      flag: {
        importListLoading: false, // 获取列表loading
        voucherDialog: false,
      },
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表单数据
      tableData: [], //表格数据
      businessList: [], // 业务类型列表
      sceneList: [], // 场景类型列表
      invoiceList: [], // 发票类型
      statusList: [], // 运单状态下拉列表
      userId: "",
      mapList: [],
      traceDialog: false,
    };
  },
  methods: {
    openMap(item) {
      this.flag.importListLoading = true;
      let params = {
        bbid: item.BBID,
      };
      getMapPath(params)
        .then((res) => {
          console.log(res);
          let { data } = res;
          this.mapList = data;
          this.traceDialog = true;
          console.log(this.mapList);
        })
        .finally(() => {
          this.flag.importListLoading = false;
        });
    },
    // 获取业务场景类型
    async getBTaskType(level, code) {
      await GetBTaskType({
        level,
        code,
      }).then(async (res) => {
        if (level == 1) {
          this.businessList = res.data || [];
          if (this.businessList.length && !this.manageForm.businessType) {
            this.manageForm.businessType = this.businessList[0].Code;
            await this.getBTaskType(2, this.manageForm.businessType);
          }
        }
        if (level == 2) {
          this.sceneList = res.data || [];
          if (this.sceneList.length && !this.manageForm.scenarioType) {
            this.manageForm.scenarioType = this.sceneList[0].Code;
            await this.getBTaskType(3, this.manageForm.scenarioType);
          }
        }
        if (level == 3) {
          this.invoiceList = res.data || [];
          if (this.invoiceList.length) {
            this.manageForm.invoiceType = this.manageForm.invoiceType
              ? this.manageForm.invoiceType
              : this.invoiceList[0].Code;
          }
        }
        this.getBillAllList();
      });
    },
    // 选择业务类型
    businessChange() {
      if (this.manageForm.businessType)
        this.getBTaskType(2, this.manageForm.businessType);
      this.sceneList = [];
      this.invoiceList = [];
      this.manageForm.scenarioType = "";
      this.manageForm.invoiceType = "";
    },
    // 选择场景类型
    sceneTypeChange() {
      if (this.manageForm.scenarioType)
        this.getBTaskType(3, this.manageForm.scenarioType);
      this.invoiceList = [];
      this.manageForm.invoiceType = "";
    },
    // 获取列表
    getBillAllList() {
      this.flag.importListLoading = true;
      BillAll({
        applicantUserID: parseInt(_getUserId()) / 5,
        ...this.manageForm,
        Id: Number(this.manageForm.Id),
        taskTypeID: this.manageForm.invoiceType,
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        dateStart:
          this.manageForm.searchDate && this.manageForm.searchDate[0]
            ? this.manageForm.searchDate[0] + " 00:00:00"
            : "",
        dateEnd:
          this.manageForm.searchDate && this.manageForm.searchDate[1]
            ? this.manageForm.searchDate[1] + " 23:59:59"
            : "",
      })
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.flag.importListLoading = false;
        });
    },
    // 搜索
    search() {
      // if (this.manageForm.Id) {
      //   if (!/^\d+$/.test(this.manageForm.Id)) {
      //     this.$message.warning("运单编号只能为数字！");
      //     return;
      //   }
      //   if (String(this.manageForm.Id).length > 8) {
      //     this.$message.warning("运单编号长度不能大于8位！");
      //     return;
      //   }
      // }
      this.pagination.page = 1;
      this.tableData = [];
      this.getBillAllList();
    },
    clearData() {
      this.$refs["manageForm"].resetFields();
      this.manageForm.status = "";
      this.manageForm.WaybillId="",
      this.manageForm.waybillType=0, 
      this.manageForm.searchDate = ["", ""];
      this.getBTaskType(1, "");
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.getBillAllList();
    },
    // 全部运单下载表格
    downloadBillList() {
      const loading = this.$loading({
        lock: true,
        text: "下载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      DownloadBillAll(
        {
          applicantUserID: parseInt(_getUserId()) / 5,
          ...this.manageForm,
          Id: Number(this.manageForm.Id),
          taskTypeID: this.manageForm.invoiceType,
          pageSize: this.pagination.pagesize,
          pageIndex: this.pagination.page,
          dateStart:
            this.manageForm.searchDate && this.manageForm.searchDate[0]
              ? this.manageForm.searchDate[0] + " 00:00:00"
              : "",
          dateEnd:
            this.manageForm.searchDate && this.manageForm.searchDate[1]
              ? this.manageForm.searchDate[1] + " 23:59:59"
              : "",
        },
        {
          responseType: "blob",
        }
      ).catch((err) => {
        let { data } = err;
        let that = this;
        if (err.type == "application/json") {
          let reader = new FileReader();
          reader.readAsText(err);
          reader.onload = function (e) {
            const { msg } = JSON.parse(reader.result);
            that.$message({
              message: `下载失败 ${msg}`,
              type: "error",
            });
          };
          return;
        }

        const blob = new Blob([data]);
        let fileName = err.headers["content-disposition"].split(";")[1].split("=")[1]; //拆解获取文件名，
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
        loading.close();
      });
    },
    //打开凭证详情弹窗
    openVoucherDialog(item) {
      this.currentItem = item;
      this.flag.voucherDialog = true;
    },
  },
  created() {
    this.userId = parseInt(_getUserId()) / 5;
    this.getBTaskType(1, "");
    // 获取运单状态列表
    GetBillStatusList().then((res) => {
      this.statusList = res.data;
    });
  },
  components: {
    EvidenceInfo,
    TXmap,
  },
};
</script>
<style lang="scss">
.goodsAllWaybill {
  .queryForm {
    display: block;

    .el-form-item {
      display: flex;
      align-items: center;

      .el-form-item__label {
        width: 100px;
      }

      .el-form-item__content {
        width: 200px;
      }
      .el-input-group__prepend{
        width: 100px;
      }
    }
  }
}
</style>
