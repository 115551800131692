import axios from "@/api/config/interceptor";
import { host, riskHost } from "@/api/config/host";
import { _paramsToQueryString } from "@/utils/utils";

// 获取发票六要素等开票信息
export const GetInvoiceTitleInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/InvoiceFlow/GetInvoiceTitleInfo`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取运单统计
export const GetBillStatistics = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/InvoiceFlow/GetBillStatistics`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取签约服务商
export const GetSignSupplier = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/InvoiceFlow/GetSignSupplier`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取票种，专票/普票
export const GetTicketType = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/InvoiceFlow/GetTicketType`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取支付方式
export const GetPaymentMethod = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/InvoiceFlow/GetPaymentMethod`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 支付并创建结算单
export const PayAndCreateStatement = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/InvoiceFlow/PayAndCreateStatement`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 结算单列表
export const GetStatementList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/InvoiceFlow/GetStatementList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 结算单明细列表
export const GetStatementDetailList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/InvoiceFlow/GetStatementDetailList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 取消结算单
export const CancelStatement = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/InvoiceFlow/CancelStatement`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 拒绝结算单
export const RefusedStatement = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/InvoiceFlow/RefusedStatement`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 回退结算单
export const FallbackStatement = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/InvoiceFlow/FallbackStatement`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 同意结算单
export const AgreeStatement = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/InvoiceFlow/AgreeStatement`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 确认收票
export const ConfirmInvoice = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/InvoiceFlow/ConfirmInvoice`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取待结算单明细
export const GetBillDetail = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/InvoiceFlow/GetBillDetail`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取业务-场景-发票类型
export const GetBTaskType = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/v3/InvoiceFlow/GetBTaskType?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取结算单 tab 状态列表
export const GetBStatementMenuStatus = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/v3/InvoiceFlow/GetBStatementMenuStatus?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 查询结算单基本信息
export const GetStatementInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/v3/InvoiceFlow/GetStatementInfo?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 待开启业务单列表
export const WaitBillList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/BillLibrary/WaitBillList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 水运风控校验
export const RiskShip = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${riskHost}/api/RiskShip?${_paramsToQueryString(params)}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 修改业务单
export const UpdateBillDetail = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/BillLibrary/UpdateBillDetail`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 删除业务单
export const DelBillDetail = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/BillLibrary/DelBillDetail`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 所有运单列表
export const BillAll = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v3/BillLibrary/BillAll`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 下载所有运单
export const DownloadBillAll = (params, type) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/BillLibrary/DownloadBillAll`, params, type)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取运单状态下拉列表
export const GetBillStatusList = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/v3/BillLibrary/GetBillStatusList`).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 开票历史数据统计
export const AmountReport = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${host}/api/v3/InvoiceFlow/AmountReport`).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 获取水运五流合一信息
export const GetWaterWaybillFiveInfo = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/v3/BillLibrary/GetBillFiveFlow?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

export const getMapPath = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v3/BillLibrary/GetTracks`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};