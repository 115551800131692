<template>
  <div class="vehicleInfoCmpt">
    <div class="imgList">
      <div class="item">
        <p>行驶证正页</p>
        <el-image
          :src="vehicleInfo.VehicleLicenseFrontPageURL"
          :preview-src-list="[vehicleInfo.VehicleLicenseFrontPageURL]"
        ></el-image>
      </div>
      <div class="item">
        <p>行驶证副页（正面）</p>
        <el-image
          :src="vehicleInfo.VehicleLicenseSubPageOnURL"
          :preview-src-list="[vehicleInfo.VehicleLicenseSubPageOnURL]"
        ></el-image>
      </div>
      <div class="item" v-if="vehicleInfo.VehicleLicenseSubPageBackURL">
        <p>行驶证副页（反面）</p>
        <el-image
          :src="vehicleInfo.VehicleLicenseSubPageBackURL"
          :preview-src-list="[vehicleInfo.VehicleLicenseSubPageBackURL]"
        ></el-image>
      </div>
      <div class="item" v-if="vehicleInfo.VehicleRTPUTL">
        <p>道路运输经营许可证</p>
        <el-image
          :src="vehicleInfo.VehicleRTPUTL"
          :preview-src-list="[vehicleInfo.VehicleRTPUTL]"
        ></el-image>
      </div>
      <div class="item" v-if="vehicleInfo.AdditionalLicenseURL">
        <p>附加证照</p>
        <el-image
          :src="vehicleInfo.AdditionalLicenseURL"
          :preview-src-list="[vehicleInfo.AdditionalLicenseURL]"
        >
        </el-image>
      </div>
    </div>
    <el-form class="vehicleInfo" label-width="150px" :model="vehicleInfo">
      <el-form-item label="车牌号">
        <el-input v-model="vehicleInfo.VehicleCarNumber" disabled></el-input>
      </el-form-item>
      <el-form-item label="车牌颜色">
        <el-input v-model="vehicleInfo.CarNumberColorName" disabled></el-input>
      </el-form-item>
      <!-- <el-form-item label="道路运输证号">
        <el-input v-model="vehicleInfo.VehicleRTP" disabled></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="行驶证有效期">
        <el-input v-model="vehicleInfo.VehicleLicenseValidDate" disabled></el-input>
      </el-form-item> -->
      <el-form-item label="车辆类型">
        <el-input v-model="vehicleInfo.VehicleTypeName" disabled></el-input>
      </el-form-item>
      <el-form-item label="载重(吨)">
        <el-input v-model="vehicleInfo.VehicleLoad" disabled></el-input>
      </el-form-item>
      <!-- <el-form-item label="是否车头">
        <el-input v-model="vehicleInfo.IsLeader" disabled></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="自重">
        <el-input v-model="vehicleInfo.VehicleTotalWeight" disabled></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="车牌类型">
        <el-input v-model="vehicleInfo.CarNumberTypeName" disabled></el-input>
      </el-form-item> -->
      <el-form-item label="能源类型">
        <el-input
          v-model="vehicleInfo.VehiclePowerTypeName"
          disabled
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="车辆识别代号">
        <el-input v-model="vehicleInfo.VehicleVIN" disabled></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="运输证有效期">
        <el-input v-model="vehicleInfo.VehicleRTPDate" disabled></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="发动机号">
        <el-input v-model="vehicleInfo.PowerNumber" disabled></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="商业险到期日">
        <el-input v-model="vehicleInfo.BInsuranceDate" disabled></el-input>
      </el-form-item> -->
      <el-form-item label="车长(米)">
        <el-input v-model="vehicleInfo.VehicleLength" disabled></el-input>
      </el-form-item>
      <!-- <el-form-item label="交强险到期日">
        <el-input v-model="vehicleInfo.CInsuranceDate" disabled></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="车宽(米)">
        <el-input v-model="vehicleInfo.VehicleWidth" disabled></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="购车日期">
        <el-input v-model="vehicleInfo.VehicleBuyDate" disabled></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="轴数">
        <el-input v-model="vehicleInfo.VehicleAxlesNumber" disabled></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="年审日期">
        <el-input v-model="vehicleInfo.InspectAnnuallyDate" disabled></el-input>
      </el-form-item> -->
      <el-form-item label="总重(吨)">
        <el-input v-model="vehicleInfo.VehicleLoadWeight" disabled></el-input>
      </el-form-item>
      <el-form-item label="所有人">
        <el-input v-model="vehicleInfo.VehicleMaster" disabled></el-input>
      </el-form-item>
      <el-form-item label="发证机关">
        <el-input
          v-model="vehicleInfo.VehicleLicesenAuthority"
          disabled
        ></el-input>
      </el-form-item>
    </el-form>
    <div style="margin:10px auto;text-align: center;">
      <el-button type="primary" @click="getCarNewLocation"
        >查看当前位置</el-button
      >
    </div>
    <!-- 地图组件dialog -->
    <el-dialog
      width="800px"
      :visible.sync="flag.showMap"
      class="deep_dialog"
      append-to-body
    >
      <span slot="title">查看地图</span>
      <!-- 地图组件 -->
      <TXmap
        style="height: 600px"
        v-if="flag.showMap"
        ref="map"
        :mapdialog="flag.showMap"
        :list="[]"
        maptype="position"
        :posinfo="posinfo"
      >
      </TXmap>
    </el-dialog>
  </div>
</template>

<script>
import TXmap from "@/components/commonCmpt/TXmap";
import { GetCarNewLocation } from "@/api/auditInfo/certified/index";
export default {
  props: ["vehicleInfo"],
  data() {
    return {
      flag: {
        showMap: false,
      },
      posinfo: {},
    };
  },
  methods: {
    // 车辆最新轨迹查询
    getCarNewLocation() {
      const loading = this.$loading({
        lock: true,
        text: "位置请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      GetCarNewLocation({ carNumber: this.vehicleInfo.CarNumber })
        .then((res) => {
          this.posinfo = res.data || {};
          this.posinfo.carNumber = this.vehicleInfo.CarNumber;
          this.flag.showMap = true;
        })
        .finally(() => {
          loading.close();
        });
    },
  },
  components: {
    TXmap,
  },
};
</script>

<style>
.el-input.is-disabled .el-input__inner {
  color: #000 !important;
}
</style>

<style lang="scss" scoped>
.vehicleInfo {
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    width: 400px;
  }
}

.imgList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  .item {
    width: 33%;
    height: 200px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      margin-bottom: 10px;
    }
  }
}
</style>
