<template>
  <div class="evidence-component">
    <!-- tab栏 -->
    <div style="display: flex">
      <div class="parent-title" v-show="voucherParentList.length > 0 && isShowDetailInfo">
        <p class="title-con" :class="{ activeTab: activeName == -1 }" @click="voucherTabChange('-1', null)">
          基本信息
        </p>
      </div>
      <div class="parent-title" v-show="voucherParentList.length > 0 && isShowFiveInfo">
        <p class="title-con" :class="{ activeTab: activeName == 1 }" @click="voucherTabChange('1', null)">
          五流合一
        </p>
      </div>
      <div v-for="item in voucherParentList" :key="item.ID" class="parent-title">
        <p class="title-con" :class="{
          activeTab: item.ID == flag.voucherTabActive && activeName == 0,
          hasContent: item.HasContent,
        }" @click="voucherTabChange('0', item)">
          {{ item.Name }}
        </p>
      </div>
    </div>
    <!-- 内容面板 -->
    <div v-for="item in voucherParentList" :key="item.ID">
      <div v-loading="flag.uploadvouchering" v-if="item.ID == flag.voucherTabActive && activeName == 0">
        <div style="margin: 15px 20px">
          <el-checkbox v-model="isRelated" style="transform:scale(1.3)" @change="getEvidenceDetail">查询关联凭证</el-checkbox>
        </div>
        <el-alert type="warning" :closable="false" style="margin: 10px 0">
          <span slot="title">
            <span>{{ item.Remark }}</span>
          </span>
        </el-alert>
        <div>
          <!-- 二级tab栏 -->
          <div style="display: flex; margin-bottom: 10px">
            <!-- 回收站和综合类不展示二级tab -->
            <div v-for="el in voucherChildList" :key="el.ID" v-if="flag.voucherTabActive !=
              '8dbb03c6-7274-49b8-934e-23f3408f37f1' &&
              flag.voucherTabActive != '37d3fa70-13b3-4fac-b112-6877fb6ee213'
              ">
              <p class="child-title" :class="{
                activeTab: el.ID == flag.voucherTabChildActive,
                hasContent: el.HasContent,
              }" @click="voucherTabChildChange(el)">
                {{ el.Name }}
              </p>
            </div>
          </div>
        </div>
        <!-- 内容面板 -->
        <template v-if="!flag.voucherTabChildActiveList.length">
          <div v-for="el in voucherChildList" :key="el.ID">
            <div v-if="el.ID == flag.voucherTabChildActive">
              <div v-if="flag.voucherTabActive == '8dbb03c6-7274-49b8-934e-23f3408f37f1'
                ">
                <el-input placeholder="请输入内容进行搜索" v-model="FileName" clearable class="input-with-select">
                  <el-select v-model="searchType" slot="prepend" placeholder="请选择">
                    <el-option label="名称" value="1"></el-option>
                    <el-option label="运单号" value="2"></el-option>
                  </el-select>
                </el-input>
                <el-button type="primary" icon="el-icon-search" @click="getEvidenceDetail()">搜索凭证
                </el-button>
                <el-button type="primary" :disabled="voucherDetailList && voucherDetailList.length == 0"
                  @click="updateEvidence('rey', null)">还原当前页</el-button>
              </div>
              <div v-else>
                <p style="margin-bottom: 10px">
                  一、{{ el.TemplateName
                  }}<span style="color: #ff8b17; cursor: pointer" @click="flag.exampleDialog = true">示例<i
                      class="el-icon-question" /></span>注明：最多一次可上传10张
                </p>
                <el-input placeholder="请输入内容进行搜索" v-model="FileName" clearable class="input-with-select">
                  <el-select v-model="searchType" slot="prepend" placeholder="请选择">
                    <el-option label="名称" value="1"></el-option>
                    <el-option label="运单号" value="2"></el-option>
                  </el-select>
                </el-input>
                <el-button type="primary" icon="el-icon-search" @click="getEvidenceDetail()">搜索凭证
                </el-button>
                <el-button type="primary" :loading="flag.uploadvouchering" @click="$refs.voucher.click()">
                  添加新凭证
                </el-button>
                <el-button type="primary" @click="addVouchers">添加凭证URL
                </el-button>
                <el-button type="primary" @click="dwonLoadEvidence" :disabled="!voucherDetailList.length">下载凭证
                </el-button>
                <el-button type="primary" @click="relationVouchers"
                  v-show="invoiceTaskId && el.TemplateName == '交易截图'">关联凭证
                </el-button>
              </div>
              <!-- 凭证列表 -->
              <div class="voucherWrap">
                <div class="item" v-for="item in voucherDetailList" :key="item.EvidenceID">
                  <i class="delete el-icon-delete" @click="updateEvidence('Del', item)" v-if="flag.voucherTabActive !=
                    '8dbb03c6-7274-49b8-934e-23f3408f37f1'
                    "></i>
                  <el-image style="width: 200px; height: 100px; margin-bottom: 10px" :src="item.EvidenceImgUrl"
                    :preview-src-list="[item.EvidenceImgUrl]">
                    <div slot="error" class="image-slot" style="
                      display: flex;
                      justify-content: center;
                      margin-top: 30px;
                    ">
                      <i class="el-icon-picture-outline" style="font-size: 50px"></i>
                    </div>
                  </el-image>
                  <el-tooltip :content="getEvidenceImgUrl(item)">
                    <p @click="downloadVoucher(item)">
                      {{ getEvidenceImgUrl(item) }}
                      <!-- {{     getEvidenceImgUrl( item.EvidenceImgUrl)  }} item.FileName || item.EvidenceImgUrl -->
                    </p>
                  </el-tooltip>
                  <div style="width: 200px; text-align: center" v-if="flag.voucherTabActive ==
                    '8dbb03c6-7274-49b8-934e-23f3408f37f1'
                    ">
                    <el-button type="primary" size="mini" @click="updateEvidence('rey', item)">还原
                    </el-button>
                  </div>
                </div>
              </div>
              <!-- 分页 -->
              <el-pagination background style="margin-top: 16px" @current-change="paginationChange"
                :current-page.sync="pagination.page" :page-size="pagination.pagesize"
                layout="total, prev, pager, next, jumper" :total="pagination.total">
              </el-pagination>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- 凭证列表 -->
          <div class="voucherWrap">
            <div class="item" v-for="item in voucherDetailList" :key="item.EvidenceID">
              <i class="delete el-icon-delete" @click="updateEvidence('Del', item)" v-if="flag.voucherTabActive !=
                '8dbb03c6-7274-49b8-934e-23f3408f37f1'
                "></i>
              <el-image style="width: 200px; height: 100px; margin-bottom: 10px" :src="item.EvidenceImgUrl"
                :preview-src-list="[item.EvidenceImgUrl]">
                <div slot="error" class="image-slot" style="
                      display: flex;
                      justify-content: center;
                      margin-top: 30px;
                    ">
                  <i class="el-icon-picture-outline" style="font-size: 50px"></i>
                </div>
              </el-image>
              <el-tooltip :content="getEvidenceImgUrl(item)">
                <p @click="downloadVoucher(item)">
                  {{ getEvidenceImgUrl(item) }}
                  <!-- {{     getEvidenceImgUrl( item.EvidenceImgUrl)  }} item.FileName || item.EvidenceImgUrl -->
                </p>
              </el-tooltip>
              <div style="width: 200px; text-align: center" v-if="flag.voucherTabActive ==
                '8dbb03c6-7274-49b8-934e-23f3408f37f1'
                ">
                <el-button type="primary" size="mini" @click="updateEvidence('rey', item)">还原
                </el-button>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <el-pagination background style="margin-top: 16px" @current-change="paginationChange"
            :current-page.sync="pagination.page" :page-size="pagination.pagesize"
            layout="total, prev, pager, next, jumper" :total="pagination.total">
          </el-pagination>
        </template>
      </div>
    </div>
    <!-- 合同示例 -->
    <el-dialog width="1200px" :title="currentVoucherObj.TemplateName" center :visible.sync="flag.exampleDialog"
      append-to-body :close-on-click-modal="false">
      <el-image v-if="JSON.stringify(currentVoucherObj) != '{}'" :src="currentVoucherObj.TemplateUrl"
        :preview-src-list="[currentVoucherObj.TemplateUrl]"></el-image>
    </el-dialog>
    <!--上传凭证-->
    <input type="file" style="display: none" @change="handleVoucherUpload" multiple ref="voucher" />
    <!-- 基本信息 -->
    <div v-if="activeName == '-1'">
      <div class="info-title">
        <div class="title-left">
          <img class="img" src="@/assets/image/waybill-icon1.png" />
          <span>平台信息</span>
        </div>
      </div>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="info-con">
            <div class="title">运单号</div>
            <div class="con">{{ currentItem.WaybillID || currentItem.WaybillNumber }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">开票申请编号</div>
            <div class="con">{{ currentItem.InvoiceTaskID }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">客户单号</div>
            <div class="con">{{ currentItem.CarriageBillID }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">溯源单号</div>
            <div class="con">{{ currentItem.OriginalWayBillID }}</div>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="info-title">
        <div class="title-left">
          <img class="img" src="@/assets/image/waybill-icon2.png" />
          <span>资金信息</span>
        </div>
      </div>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="info-con">
            <div class="title">运费</div>
            <div class="con">
              {{ currentItem.CarriageTotalPrice | formatMoney }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">运费服务费/税费</div>
            <div class="con">
              {{ currentItem.ExpensesOfTaxation | formatMoney }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">技术服务费/税费</div>
            <div class="con">{{ currentItem.TecServiceFee | formatMoney }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">运单总金额</div>
            <div class="con">{{ currentItem.TotalAmount | formatMoney }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 15px">
        <el-col :span="6">
          <div class="info-con">
            <div class="title">运输单价</div>
            <div class="con">
              {{ currentItem.DriverCarryPrice | formatMoney }}
            </div>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="info-title">
        <div class="title-left">
          <img class="img" src="@/assets/image/waybill-icon3.png" />
          <span>服务商信息</span>
        </div>
        <el-link class="detail-info" @click="showSupplyDialog(null, 1)">详情</el-link>
      </div>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="info-con">
            <div class="title">服务商名称</div>
            <div class="con">{{ currentItem.EnterpriseFullName }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">发票号</div>
            <div class="con">{{ currentItem.InvoiceNumber }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">开票完成时间</div>
            <div class="con">{{ currentItem.ReceiptInvoiceTime }}</div>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="info-title">
        <div class="title-left">
          <img class="img" src="@/assets/image/waybill-icon4.png" />
          <span>货主信息</span>
        </div>
        <el-link class="detail-info" @click="showSupplyDialog(null, 2)">详情</el-link>
      </div>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="info-con">
            <div class="title">货主单位</div>
            <div class="con">{{ currentItem.GoodsOwnerName }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">运单导入时间</div>
            <div class="con">{{ currentItem.ImportTime }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">开票申请时间</div>
            <div class="con">{{ currentItem.InvoiceApplyDatetime }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">确认支付时间</div>
            <div class="con">{{ currentItem.PayCompleteDatetime }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 15px">
        <el-col :span="6">
          <div class="info-con">
            <div class="title">发票类型</div>
            <div class="con">{{ currentItem.InvoiceTypeName }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">业务类型</div>
            <div class="con">{{ currentItem.TaskTypeName }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">场景类型</div>
            <div class="con">{{ currentItem.TaskSceneName }}</div>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="info-title">
        <div class="title-left">
          <img class="img" src="@/assets/image/waybill-icon5.png" />
          <span>司机信息</span>
        </div>
        <el-link class="detail-info" @click="getPersonInfo(null, currentItem.DriverUserID)">详情</el-link>
      </div>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-row>
            <div class="info-con">
              <div class="title">司机姓名</div>
              <div class="con">
                {{
                  currentItem.DriverName || detailInfo?.WaybillInfo?.DriverName
                }}
              </div>
            </div>
          </el-row>
          <el-row style="margin: 15px 0px">
            <div class="info-con">
              <div class="title">司机手机号</div>
              <div class="con">
                {{
                  currentItem.DriverPhone ||
                  detailInfo?.WaybillInfo?.DriverPhone
                }}
              </div>
            </div>
          </el-row>
          <el-row>
            <div class="info-con">
              <div class="title">司机身份证号</div>
              <div class="con">
                {{
                  currentItem.DriverIDCard ||
                  detailInfo?.WaybillInfo?.DriverIDCard
                }}
              </div>
            </div>
          </el-row>
        </el-col>

        <el-col :span="6">
          <div class="info-con">
            <div class="title">收款人姓名</div>
            <div class="con">
              {{ currentItem.PayeeName || detailInfo?.WaybillInfo?.PayeeName }}
            </div>
          </div>
          <div class="info-con" style="margin: 15px 0px">
            <div class="title">收款人手机号</div>
            <div class="con">
              {{
                currentItem.PayeePhone || detailInfo?.WaybillInfo?.PayeePhone
              }}
            </div>
          </div>
          <div class="info-con">
            <div class="title">收款人身份证号</div>
            <div class="con">
              {{
                currentItem.PayeeIDCard || detailInfo?.WaybillInfo?.PayeeIDCard
              }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">车队长姓名</div>
            <div class="con">
              {{
                currentItem.LeaderName || detailInfo?.WaybillInfo?.LeaderName
              }}
            </div>
          </div>
          <div class="info-con" style="margin: 15px 0px">
            <div class="title">车队长手机号</div>
            <div class="con">
              {{
                currentItem.LeaderPhone || detailInfo?.WaybillInfo?.LeaderPhone
              }}
            </div>
          </div>
          <div class="info-con">
            <div class="title">车队长身份证号</div>
            <div class="con">
              {{
                currentItem.LeaderIDCard ||
                detailInfo?.WaybillInfo?.LeaderIDCard
              }}
            </div>
          </div>
        </el-col>
      </el-row>

      <el-divider></el-divider>
      <div class="info-title">
        <div class="title-left">
          <img class="img" src="@/assets/image/waybill-icon6.png" />
          <span>车辆信息</span>
        </div>
        <div>
          <el-link class="detail-info" @click="openMap(detailInfo.WaybillInfo)" style="margin-right: 8px">查看轨迹
          </el-link>
          <el-link class="detail-info" @click="showVehicleInfo">详情</el-link>
        </div>
      </div>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="info-con">
            <div class="title">车牌号码</div>
            <div class="con">{{ currentItem.CarNumber }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">车辆类型</div>
            <div class="con">{{ currentItem.VehicleTypeName }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">装货净重（吨）</div>
            <div class="con">{{ currentItem.LoadNetWeight }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">货品名称</div>
            <div class="con">{{ currentItem.GoodsName }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 15px">
        <el-col :span="6">
          <div class="info-con">
            <div class="title">货品单位</div>
            <div class="con">{{ currentItem.GoodsUnit }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">装货数量</div>
            <div class="con">{{ currentItem.DriverAllotCount }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">卸货净重（吨）</div>
            <div class="con">{{ currentItem.UnloadNetWeight }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">装货时间</div>
            <div class="con">{{ currentItem.LoadingDatetime }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 15px">
        <el-col :span="6">
          <div class="info-con">
            <div class="title">签收时间</div>
            <div class="con">{{ currentItem.SignDatetime }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">起始地</div>
            <div class="con">{{ currentItem.OriginAddress }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">到达地</div>
            <div class="con">{{ currentItem.ArriveAddress }}</div>
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="info-title">
        <div class="title-left">
          <img class="img" src="@/assets/image/waybill-icon7.png" />
          <span>运单状态</span>
        </div>
      </div>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="info-con">
            <div class="title">运单状态</div>
            <div class="con">
              {{ currentItem.TaskStatus == 3 ? "已开票" : "未开票" }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">运单上报状态</div>
            <div class="con">{{ currentItem.ReportStatus }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">资金上报状态</div>
            <div class="con">{{ currentItem.ReportZJStatus }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">上报人</div>
            <div class="con">{{ currentItem.ReportUser }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 15px">
        <el-col :span="6">
          <div class="info-con">
            <div class="title">运单备注</div>
            <div class="con">{{ currentItem.ReportRemark }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">资金备注</div>
            <div class="con">{{ currentItem.ReportZJRemark }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">上报时间</div>
            <div class="con">{{ currentItem.ReportTime }}</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-con">
            <div class="title">风控时间</div>
            <div class="con">{{ detailInfo.WaybillInfo.RiskTime }}</div>
          </div>
        </el-col>
        <el-col :span="6" style="margin-top: 15px">
          <div class="info-con">
            <div class="title">风控备注</div>
            <el-tooltip placement="top" :content="detailInfo.WaybillInfo.Remark">
              <div class="con">{{ detailInfo.WaybillInfo.Remark }}</div>
            </el-tooltip>
          </div>
        </el-col>
        <el-col :span="6" style="margin-top: 15px">
          <div class="info-con">
            <div class="title">错误信息</div>
            <el-tooltip placement="top" :content="detailInfo.WaybillInfo.ErrorDesc">
              <div class="con">{{ detailInfo.WaybillInfo.ErrorDesc }}</div>
            </el-tooltip>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 15px" v-if="detailInfo.WaybillInfo.EvaluateRemark">
        <el-col>
          <div class="info-con">
            <div class="title">运单评价</div>
            <div class="con1">{{ detailInfo.WaybillInfo.EvaluateRemark }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 15px">
        <el-col>
          <div class="info-con">
            <div class="title">人工审核备注</div>
            <div class="con1">{{ detailInfo.WaybillInfo.Remark1 }}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--五流合一-->
    <div v-if="activeName == '1'">
      <div style="display: flex; align-items: center; position: relative">
        <el-image style="width: 80px; height: 80px" :src="ossHost + 'fiveStream/1.png'"></el-image>
        <div style="font-size: 16px; font-weight: bold; color: #333">
          一、合同流:<span style="font-size: 16px; font-weight: 400; margin-left: 8px">运单关联的合同</span>
        </div>
      </div>
      <div style="position: relative">
        <el-image style="
            width: 14px;
            height: 100%;
            position: absolute;
            left: 32px;
            top: 0px;
          " :src="ossHost + 'fiveStream/line1.png'"></el-image>
        <div style="
            margin-bottom: 16px;
            background: #ecf2ff;
            padding: 16px;
            margin-left: 80px;
          " v-if="JSON.stringify(detailInfo) != '{}'">
          <el-table :row-class-name="tableRowClassName" :data="contractList"
            :header-cell-style="{ color: '#333333', background: '#fff' }">
            <el-table-column fixed align="center" type="index" prop="index" label="序号" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="Name" label="合同名称" show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column align="center" prop="DateTime" label="签订时间" show-overflow-tooltip>
                        </el-table-column> -->
            <el-table-column fixed="right" align="center" label="操作">
              <template slot-scope="scope">
                <el-link type="primary" @click="previewPDF(scope.row.Url)">查看合同</el-link>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background class="pagination1" @current-change="handleCurrentChange1"
            :current-page.sync="pagination1.page" :page-size="pagination1.pagesize"
            layout="total, prev, pager, next, jumper" :total="pagination1.total">
          </el-pagination>
        </div>
      </div>
      <div style="display: flex; align-items: center; position: relative">
        <el-image style="width: 80px; height: 80px" :src="ossHost + 'fiveStream/2.png'"></el-image>
        <div style="font-size: 16px; font-weight: bold; color: #333">
          二、信息流:<span style="font-size: 16px; font-weight: 400; margin-left: 8px">运单的主要信息</span>
        </div>
      </div>
      <div style="position: relative">
        <el-image style="
            width: 14px;
            height: 100%;
            position: absolute;
            left: 32px;
            top: 0px;
          " :src="ossHost + 'fiveStream/line2.png'"></el-image>

        <el-form style="background: #fff9f3; margin-left: 80px; padding-top: 16px" :inline="true" :model="detailInfo"
          v-if="JSON.stringify(detailInfo) != '{}'" label-width="130px">
          <el-form-item label="货主单位：">
            <p @click="showSupplyDialog(null, 2)" style="
                width: 15vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              ">
              <el-link type="primary">{{
                detailInfo.WaybillInfo.GoodsOwnerName
              }}</el-link>
            </p>
          </el-form-item>
          <el-form-item label="服务商：">
            <p @click="showSupplyDialog(null, 1)" style="
                width: 15vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              ">
              <el-link type="primary">{{
                detailInfo.WaybillInfo.enterpriseFullName
              }}</el-link>
            </p>
          </el-form-item>
          <el-form-item label="司机姓名：">
            <p @click="getPersonInfo(null, currentItem.DriverUserID)" style="
                width: 15vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              ">
              <el-link type="primary">{{
                detailInfo.WaybillInfo.DriverName
              }}</el-link>
            </p>
          </el-form-item>
          <!-- <el-form-item label="司机手机号：">
                        <p @click="getPersonInfo(null, currentItem.DriverUserID)"
                            style="width: 15vw; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                            <el-link type="primary">{{
                            detailInfo.WaybillInfo.DriverPhone
                            }}</el-link>
                        </p>
                    </el-form-item> -->

          <el-form-item label="车牌号：">
            <p @click="showVehicleInfo" style="
                width: 15vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              ">
              <el-link type="primary">{{
                detailInfo.WaybillInfo.CarNumber
              }}</el-link>
            </p>
          </el-form-item>
          <el-form-item label="起始地：">
            <el-tooltip class="item" effect="dark" :content="detailInfo.WaybillInfo.OriginAddress"
              placement="bottom-start">
              <p style="
                  width: 15vw;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                ">
                {{ detailInfo.WaybillInfo.OriginAddress }}
              </p>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="到达地：">
            <el-tooltip class="item" effect="dark" :content="detailInfo.WaybillInfo.ArriveAddress"
              placement="bottom-start">
              <p style="
                  width: 15vw;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                ">
                {{ detailInfo.WaybillInfo.ArriveAddress }}
              </p>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="装货时间：">
            <p style="
                width: 15vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              ">
              {{ detailInfo.WaybillInfo.LoadingDatetime }}
            </p>
          </el-form-item>
          <el-form-item label="签收时间：">
            <p style="
                width: 15vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              ">
              {{ detailInfo.WaybillInfo.SignDatetime }}
            </p>
          </el-form-item>
          <el-form-item label="货品名称:">
            <p style="
                width: 15vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              ">
              {{ detailInfo.WaybillInfo.GoodsName }}
            </p>
          </el-form-item>
          <el-form-item label="装货净重：">
            <p style="
                width: 15vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              ">
              {{ detailInfo.WaybillInfo.LoadNetWeight }}吨
            </p>
          </el-form-item>
          <!-- 委托代开费 -->
          <el-form-item label="运费：" v-if="$entrustSceneIds.includes(
            Number(detailInfo.WaybillInfo.TaskSceneTypeID)
          )
            ">
            <p style="
                width: 15vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              ">
              {{
                detailInfo.WaybillInfo.EntrustedCollectionAmount | formatMoney
              }}
            </p>
          </el-form-item>
          <!-- 运费 -->
          <el-form-item label="运费：" v-else>
            <p style="
                width: 15vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              ">
              {{ detailInfo.WaybillInfo.CarriageTotalPrice | formatMoney }}
            </p>
          </el-form-item>
        </el-form>
      </div>

      <div style="display: flex; align-items: center; position: relative">
        <el-image style="width: 80px; height: 80px" :src="ossHost + 'fiveStream/3.png'"></el-image>
        <div style="font-size: 16px; font-weight: bold; color: #333">
          三、轨迹流:<span style="font-size: 16px; font-weight: 400; margin-left: 8px">从起始地→到达地的路途</span>
        </div>
        <el-image style="
            width: 14px;
            height: 120px;
            position: absolute;
            left: 32px;
            top: 80px;
          " :src="ossHost + 'fiveStream/line3.png'"></el-image>
      </div>
      <div style="
          margin-bottom: 16px;
          background: #ecf2ff;
          padding: 16px;
          margin-left: 80px;
        " v-if="JSON.stringify(detailInfo) != '{}'">
        <el-table :data="traceList" :header-cell-style="{ color: '#666', background: '#fff' }">
          <el-table-column fixed align="center" type="index" prop="index" label="序号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="Name" label="起始地" show-overflow-tooltip>
            {{ detailInfo.WaybillInfo.OriginAddress }}
          </el-table-column>
          <el-table-column align="center" prop="DateTime" label="到达地" show-overflow-tooltip>
            {{ detailInfo.WaybillInfo.ArriveAddress }}
          </el-table-column>
          <el-table-column align="center" prop="Name" label="装货时间" show-overflow-tooltip>
            {{ detailInfo.WaybillInfo.LoadingDatetime }}
          </el-table-column>
          <el-table-column align="center" prop="DateTime" label="签收时间" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ detailInfo.WaybillInfo.SignDatetime }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作">
            <template slot-scope="scope">
              <el-link type="primary" @click="openMap(detailInfo.WaybillInfo)">查看轨迹</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="display: flex; align-items: center; position: relative">
        <el-image style="width: 80px; height: 80px" :src="ossHost + 'fiveStream/4.png'"></el-image>
        <div style="
            font-size: 16px;
            font-weight: bold;
            color: #333;
            display: flex;
            align-items: center;
          ">
          四、资金流:<span style="font-size: 16px; font-weight: 400; margin-left: 8px">监管银行提供的电子回单</span><span
            style="color: red; display: flex; align-items: center">(更多凭证请<el-link class="voucher-link"
              @click="toFunds">前往凭证</el-link>分类中查看)</span>
        </div>
      </div>
      <div style="position: relative" class="voucher-list">
        <el-image style="
            width: 14px;
            height: 100%;
            position: absolute;
            left: 32px;
            top: 0px;
          " :src="ossHost + 'fiveStream/line4.png'"></el-image>
        <div style="
            margin-bottom: 16px;
            background: #fff9f3;
            padding: 16px;
            margin-left: 80px;
          " v-if="JSON.stringify(detailInfo) != '{}'">
          <el-table :row-class-name="tableRowClassName1" :data="vouchersList"
            :header-cell-style="{ color: '#666', background: '#fff' }" empty-text="更多凭证请前往凭证分类中查看">
            <el-table-column fixed align="center" type="index" prop="index" label="序号" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="Name" label="交易凭证" show-overflow-tooltip>
              <template slot-scope="scope">
                <div>
                  {{ scope.row.Name }}
                  (
                  <span :style="{
                    color: scope.row.IsItOnline ? 'green' : 'red',
                  }">
                    {{ scope.row.IsItOnline ? "线上" : "线下" }}
                  </span>
                  )
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column align="center" prop="DateTime" label="交易时间" show-overflow-tooltip>
                        </el-table-column> -->
            <el-table-column fixed="right" align="center" label="操作">
              <template slot-scope="scope">
                <el-link type="primary" @click="previewPDF(scope.row.Url)">查看交易凭证</el-link>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background class="pagination2" @current-change="handleCurrentChange2"
            :current-page.sync="pagination2.page" :page-size="pagination2.pagesize"
            layout="total, prev, pager, next, jumper" :total="pagination2.total">
          </el-pagination>
        </div>
      </div>

      <div style="display: flex; align-items: center; position: relative">
        <el-image style="width: 80px; height: 80px" :src="ossHost + 'fiveStream/5.png'"></el-image>
        <div style="font-size: 16px; font-weight: bold; color: #333">
          五、发票流:<span style="font-size: 16px; font-weight: 400; margin-left: 8px">运单关联的发票与清单附件</span>
        </div>
      </div>
      <div style="position: relative">
        <div style="
            margin-bottom: 16px;
            background: #ecf2ff;
            padding: 16px;
            margin-left: 80px;
          " v-if="JSON.stringify(detailInfo) != '{}'">
          <el-table :data="invoicesList" :header-cell-style="{ color: '#333', background: '#fff' }">
            <el-table-column fixed align="center" type="index" prop="index" label="序号" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" label="发票图片">
              <template slot-scope="scope">
                <el-image style="width: 100px; height: 100px" :src="scope.row.Url" :preview-src-list="[scope.row.Url]"
                  v-if="scope.row.Url"></el-image>
                <span v-else style="color: red">已由系统回填信息</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="Code" label="发票代码" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="Name" label="发票号码" show-overflow-tooltip>
            </el-table-column>
            <el-table-column align="center" prop="DateTime" label="开票日期" show-overflow-tooltip>
            </el-table-column>
            <el-table-column fixed="right" align="center" label="操作">
              <template slot-scope="scope">
                <el-link v-if="scope.row.Url" type="primary" @click="previewPDF(scope.row.Url)">下载</el-link>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background class="pagination1" @current-change="handleCurrentChange3"
            :current-page.sync="pagination3.page" :page-size="pagination3.pagesize"
            layout="total, prev, pager, next, jumper" :total="pagination3.total">
          </el-pagination>
          <!-- <el-table
            :data="waybillInvoiceList"
            :header-cell-style="{ color: '#333', background: '#fff' }"
            style="margin-top: 15px"
          >
            <el-table-column
              fixed
              align="center"
              type="index"
              prop="index"
              label="序号"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column align="center" label="发票图片">
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.URL"
                  :preview-src-list="[scope.row.URL]"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="InvoiceNumber"
              label="发票号码"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column fixed="right" align="center" label="操作">
              <template slot-scope="scope">
                <el-link type="primary" @click="previewPDF(scope.row.URL)"
                  >下载</el-link
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            class="pagination1"
            @current-change="handleWaybillInvoiceChange"
            :current-page.sync="queryParams.page"
            :page-size="queryParams.pagesize"
            layout="total, prev, pager, next, jumper"
            :total="queryParams.total"
          >
          </el-pagination> -->
        </div>
      </div>
    </div>
    <!-- 地图组件dialog -->
    <el-dialog width="800px" :visible.sync="flag.showMap" append-to-body :close-on-click-modal="false">
      <!-- 地图组件 -->
      <TXmap style="height: 600px" v-if="flag.showMap" ref="map" :list="mapList"></TXmap>
    </el-dialog>
    <!--服务商/货主信息弹框-->
    <el-dialog top="15vh" class="dialog" :visible.sync="supplyDialog" width="1000px" append-to-body
      :close-on-click-modal="false">
      <span slot="title">{{ type == 1 ? "服务商信息" : "货主信息" }}</span>
      <div>
        <SupplyInfo v-if="type == 1" :supplyId="supplyId"></SupplyInfo>
        <BossInfo v-if="type == 2" :supplyId="supplyId" />
      </div>
    </el-dialog>
    <!--人员信息弹框-->
    <el-dialog top="15vh" class="dialog" :visible.sync="personDialog" width="1300px" append-to-body
      :close-on-click-modal="false">
      <span slot="title">人员信息</span>
      <DriverInfo :driverInfo="driverInfo" />
    </el-dialog>
    <!--显示车辆信息弹框-->
    <el-dialog top="15vh" class="dialog" :visible.sync="vehicleDialog" width="1300px" append-to-body
      :close-on-click-modal="false">
      <span slot="title">车辆信息</span>
      <VehicleInfo :vehicleInfo="vehicleInfo" />
    </el-dialog>
    <!-- 添加凭证URLdialog -->
    <el-dialog :visible.sync="flag.addVouchersDialog" title="添加凭证URl" center append-to-body v-if="flag.addVouchersDialog">
      <div style="
          color: red;
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 10px;
        ">
        注意：多个链接请用逗号隔开
      </div>
      <el-input type="textarea" :rows="2" autosize placeholder="请输入" v-model="vouchersUrls"></el-input>
      <div style="text-align: center; margin-top: 16px">
        <el-button style="margin-right: 16px" type="primary" size="mini" @click="updateVouchers">保存</el-button>
        <el-button type="primary" size="mini" @click="flag.addVouchersDialog = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 关联凭证 dialog -->
    <el-dialog :visible.sync="flag.relationVouchersDialog" title="关联凭证" center top="15vh" width="1200px" append-to-body
      :close-on-click-modal="false" v-if="flag.relationVouchersDialog" :show-close="false">
      <relationVoucher ref="relationVoucher" :invoiceTaskId="invoiceTaskId"></relationVoucher>

      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="(flag.relationVouchersDialog = false), getEvidenceDetail()">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="visible" :title="title" :append-to-body="true" width="50%">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="percentComplete" />
      <template #footer>
        <span>
          <el-button type="primary">取消</el-button>
          <el-button :type="type" @click="doSave()" :disabled="disabled">保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {
  GetEvidenceParentType,
  GetEvidenceChildType,
  GetEvidenceDetail,
  AddEvidence,
  UpdateEvidence,
  AddEvidenceByUrls,
  DwonLoadEvidence
} from "@/api/evidenceInfo/evidenceInfo";
import {
  getDriverDetails,
  getCarInfo,
  getMapPath,
  FileDowUrl,
} from "@/api/common/common";
import { GetWaybillInvoice } from "@/api/waybill/otherAndAllBill/index";
import SupplyInfo from "@/components/allWayBillCmpt/supplyInfoDialog";
import BossInfo from "@/components/allWayBillCmpt/bossInfoDialog";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import TXmap from "@/components/commonCmpt/TXmap";
import basicMixin from "@/mixins/basic";
import relationVoucher from "@/components/businessCmpt/relationVoucher";
export default {
  mixins: [basicMixin],
  props: {
    // 运单号/待结算单号
    businessID: "",
    // 结算单号
    invoiceTaskId: "",
    // 是否展示基本信息
    isShowDetailInfo: {
      type: Boolean,
      default: false,
    },
    // 是否是线下付款凭证
    isShowOfflineVoucher: {
      type: Boolean,
      default: false,
    },
    // 是否展示五流合一
    isShowFiveInfo: {
      type: Boolean,
      default: false,
    },
    // 基本信息内容
    currentItem: {
      type: Object,
      default: () => { },
    },
    //五流合一详情信息
    detailInfo: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      disabled: true,
      visible: false,
      BlobBase: null,
      title: "",
      percentComplete: 0,
      FileName: "", // 凭证名称搜索
      searchType: "1", // 1 名称 2 运单号
      activeName: 0,
      isRelated: false,
      flag: {
        voucherDialog: false, //凭证dialog
        openVoucherDialogIng: false, //打开凭证dialog中
        voucherTabActive: "", //选中的凭证父级ID
        voucherTabChildActive: "", //选中的凭证子级ID
        voucherTabChildActiveList: [], //全部凭证子级ID
        exampleDialog: false, //合同示例dialog
        uploadvouchering: false, //上传凭证中
        showMap: false, //显示地图
        addVouchersDialog: false, //添加凭证URL
        relationVouchersDialog: false, // 关联凭证
        isShowOfflineVoucher: false,
      },
      pagination: {
        pagesize: 10,
        page: 1,
        total: 0,
      },
      voucherParentList: [], // 父级凭证类别
      voucherChildList: [], // 子级凭证类别
      voucherDetailList: [], //凭证明细
      isRecycle: false, // 是否回收站
      currentVoucherObj: {}, // 选中的子级类别
      invoicesList: [], // 五流合一 发票流列表
      vouchersList: [], // 五流合一 资金流列表
      contractList: [], // 五流合一 合同流列表
      mapList: [], // 轨迹列表
      traceList: [{}], // 轨迹列表
      pagination1: {
        page: 1,
        pagesize: 4,
        total: 0,
      }, // 五流合一分页合同
      pagination2: {
        page: 1,
        pagesize: 4,
        total: 0,
      }, // 五流合一分页资金
      pagination3: {
        page: 1,
        pagesize: 4,
        total: 0,
      }, // 五流合一分页发票
      supplyDialog: false, // 服务商/货主信息弹框
      supplyId: "", // 服务商/货主id
      type: "", // 根据类型显示弹框 1是服务商 2是货主
      personDialog: false, // 人员信息弹框
      driverInfo: {}, //  司机 收款人信息
      vehicleInfo: {}, // 车辆信息
      vehicleDialog: false, // 车辆信息弹框
      traceDialog: false, // 查看轨迹组件
      vouchersUrls: "", //凭证URL内容
      waybillInvoiceList: [], // 五流合一线下发票流列表
      queryParams: {
        page: 1,
        pagesize: 4,
        total: 0,
      },
    };
  },
  methods: {
    getEvidenceImgUrl(item) {
      return (
        item.FileName || item.EvidenceImgUrl.split("/").pop().split("?")[0]
      );
    },
    // 获取凭证父级类型
    getEvidenceParentType() {
      GetEvidenceParentType({}).then((res) => {
        this.voucherParentList = res.data || [];
        if (res.data && res.data.length) {
          // 如果直接上传线下凭证，父级则直接定位到资金类
          if (this.flag.isShowOfflineVoucher) {
            this.flag.voucherTabActive = "fc1fcb9b-f257-4897-971c-9420fbd0bc05";
            this.parentID = "fc1fcb9b-f257-4897-971c-9420fbd0bc05";
          } else {
            this.flag.voucherTabActive = res.data[0].ID;
            this.parentID = res.data[0].ID;
          }
          this.getEvidenceChildType();
        }
      });
    },
    // 切换凭证父级
    voucherTabChange(type, item) {
      this.flag.isShowOfflineVoucher = false;
      this.activeName = type;
      if (item) {
        if (item.Code == "HSZ" || item.Code == "ZH") {
          this.flag.voucherTabChildActive = item.ID;
        } else {
          this.flag.voucherTabChildActive = ''
        }
        this.isRecycle = item.Code == "HSZ" ? true : false;
        this.flag.voucherTabActive = item.ID;
        this.FileName = "";
        this.searchType = "1";
        // 获取子级凭证类型
        this.getEvidenceChildType();
      }
      // 五流合一
      if (type == "1") {
        this.cancleFiveInfo();
      }
    },
    // 跳转到资金类
    toFunds() {
      this.activeName = "0";
      this.isRecycle = false;
      this.voucherParentList.map((it) => {
        if (it.ID == "fc1fcb9b-f257-4897-971c-9420fbd0bc05") {
          this.flag.voucherTabActive = "fc1fcb9b-f257-4897-971c-9420fbd0bc05";
        }
      });
      // 获取子级凭证类型
      this.getEvidenceChildType();
    },
    // 获取凭证子级类型
    getEvidenceChildType() {
      this.pagination.page = 1;
      GetEvidenceChildType({
        parentID: this.flag.voucherTabActive,
        businessID: this.businessID,
        isRecycle: this.isRecycle,
        isRelated: this.isRelated,
      }).then((res) => {
        this.flag.voucherTabChildActiveList = []
        this.voucherChildList = res.data || [];
        if (res.data && res.data.length) {
          // 如果直接上传线下凭证，子级则直接定位到线下凭证
          if (this.flag.isShowOfflineVoucher) {
            this.flag.voucherTabChildActive =
              "935fbaf2-83f4-4cf9-8c1a-8e435f7b7015";
            this.currentVoucherObj = res.data.filter(
              (it) => it.ID == "935fbaf2-83f4-4cf9-8c1a-8e435f7b7015"
            )[0];
          } else {
            res.data.map(it => {
              this.flag.voucherTabChildActiveList.push(it.ID)
            })
            // this.flag.voucherTabChildActive = res.data[0].ID;
            // this.currentVoucherObj = res.data[0];
          }
          // 获取凭证明细
          this.getEvidenceDetail();
        }
      });
    },
    // 切换凭证子级
    voucherTabChildChange(item) {
      this.flag.voucherTabChildActiveList = []
      this.flag.isShowOfflineVoucher = false;
      this.currentVoucherObj = item;
      this.flag.voucherTabChildActive = item.ID;
      this.pagination.page = 1;
      this.FileName = "";
      this.searchType = "1";
      // 获取凭证明细
      this.getEvidenceDetail();
    },
    // 获取凭证明细
    getEvidenceDetail() {
      this.flag.uploadvouchering = true;
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        businessID: this.businessID,
        isRecycle: this.isRecycle,
        isRelated: this.isRelated,
        ...(this.searchType == 1
          ? {
            FileName: this.FileName,
          }
          : {
            WaybillId: this.FileName,
          }),
      };
      if (this.flag.voucherTabChildActive == '') {
        params.EvidenceTypeIDList = this.flag.voucherTabChildActiveList
      } else {
        params.evidenceTypeID = this.flag.voucherTabChildActive
      }
      GetEvidenceDetail(params)
        .then((res) => {
          this.voucherDetailList = res.data.DataList || [];
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.flag.uploadvouchering = false;
        });
    },
    // 下载凭证
    dwonLoadEvidence() {
      this.flag.uploadvouchering = true;
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        evidenceTypeID: this.flag.voucherTabChildActive,
        businessID: this.businessID,
        isRecycle: this.isRecycle,
        isRelated: this.isRelated,
        ...(this.searchType == 1
          ? {
            FileName: this.FileName,
          }
          : {
            WaybillId: this.FileName,
          }),
      };
      DwonLoadEvidence(params)
        .then((res) => {
          this.$notify({
            title: "下载提示",
            message: res.data,
            position: "top-right",
            type: "success",
          });
        })
        .finally(() => {
          this.flag.uploadvouchering = false;
        });
    },
    doSave() {
      const loading = this.$loading({
        lock: true,
        text: "下载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var fileName = this.getEvidenceImgUrl(this.downloadItem);
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const link = document.createElement("a");
        link.download = fileName;
        link.style.display = "none";
        link.href = URL.createObjectURL(this.BlobBase);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href); // 释放URL 对象
        document.body.removeChild(link);
        loading.close();
      } else {
        // IE10+下载
        navigator.msSaveBlob(this.BlobBase, fileName);
        loading.close();
      }
    },
    //下载凭证
    downloadVoucher(item) {
      window.open(item.EvidenceImgUrl, "_blank");
      // this.percentComplete=0;
      // this.disabled=true;
      // var fileName = this.getEvidenceImgUrl(item);
      // const agent = new https.Agent({
      //   rejectUnauthorized: false,
      // });
      // let that = this;
      // this.visible = true;
      // this.downloadItem=item;
      // this.title = "下载" + fileName;
      //

      //   .get(item.EvidenceImgUrl, {
      //     httpsAgent: agent,
      //     responseType: "blob",
      //     onDownloadProgress: (e) => {
      //       this.percentComplete = Math.floor((e.loaded / e.total) * 100);
      //     },
      //   })
      //   .then((res) => {
      //     let { data } = res;
      //     console.log("res", res);
      //     if (res.type == "application/json") {
      //       let reader = new FileReader();
      //       reader.readAsText(res);
      //       reader.onload = function (e) {
      //         const { msg } = JSON.parse(reader.result);
      //         debugger
      //         that.visible = false;
      //         that.$message({
      //           message: `下载失败 ${msg}`,
      //           type: "error",
      //         });
      //       };
      //       return;
      //     }
      //     this.BlobBase = new Blob([data]);
      //     this.disabled=false;
      //   })
      //   .catch((res) => {
      //     that.visible = false;
      //     this.$message.error("下载失败");
      //   });
      // const loading = this.$loading({
      //   lock: true,
      //   text: "下载中...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });

      // const loading = this.$loading({
      //   lock: true,
      //   text: "下载中...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });

      // FileDowUrl(
      //   {
      //     json: item.EvidenceImgUrl,
      //     taskSceneTypeID: 0,
      //   },
      //   {
      //     responseType: "blob",
      //   }
      // )
      //   .then((res) => {
      //     console.log("res", res);
      //   })
      //   .catch((err) => {
      //     let { data } = err;
      //     let that = this;
      //     if (err.type == "application/json") {
      //       let reader = new FileReader();
      //       reader.readAsText(err);
      //       reader.onload = function (e) {
      //         const { msg } = JSON.parse(reader.result);
      //         that.$message({
      //           message: `下载失败 ${msg}`,
      //           type: "error",
      //         });
      //         loading.close();
      //       };
      //       return;
      //     }

      //     const blob = new Blob([data]);
      //     let fileName =
      //       item.FileName ||
      //       err.headers["content-disposition"].split(";")[1].split("=")[1]; //拆解获取文件名，
      //     fileName = decodeURIComponent(fileName);

      //     if ("download" in document.createElement("a")) {
      //       // 非IE下载
      //       const elink = document.createElement("a");
      //       elink.download = fileName;
      //       elink.style.display = "none";
      //       elink.href = URL.createObjectURL(blob);
      //       document.body.appendChild(elink);
      //       elink.click();
      //       URL.revokeObjectURL(elink.href); // 释放URL 对象
      //       document.body.removeChild(elink);
      //     } else {
      //       // IE10+下载
      //       navigator.msSaveBlob(blob, fileName);
      //     }
      //     loading.close();
      //   });
    },
    //上传凭证
    handleVoucherUpload(e) {
      if (e.srcElement.files.length > 10) {
        this.$message.warning("最多可同时上传10个文件");
        this.$refs.voucher.value = null;
        return;
      }
      this.flag.uploadvouchering = true;
      let count = 0;
      let fileLength = e.srcElement.files.length;
      for (let i = 0; i < fileLength; i++) {
        let formData = new FormData();
        formData.append("file", e.srcElement.files[i]);
        formData.append("BusinessID", this.businessID);
        formData.append("EvidenceTypeID", this.flag.voucherTabChildActive);
        AddEvidence(formData)
          .then((res) => { })
          .finally(() => {
            this.$refs.voucher.value = null;
            count += 1;
            if (count === fileLength) {
              //更新凭证列表
              this.pagination.page = 1;
              this.getEvidenceDetail();
              this.flag.uploadvouchering = false;
            }
          });
      }
    },
    //删除/还原凭证
    updateEvidence(type, item) {
      let text =
        type == "Del"
          ? "是否确定删除该凭证到回收站"
          : item
            ? "是否还原该凭证"
            : "是否还原当前页的所有凭证";
      this.$confirm(text, "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = { evidenceID: [], operatortype: type };
          if (item) {
            params.evidenceID = [item.EvidenceID];
          } else {
            this.voucherDetailList.forEach((el) => {
              params.evidenceID.push(el.EvidenceID);
            });
          }
          this.flag.uploadvouchering = true;
          UpdateEvidence(params)
            .then((res) => {
              //更新凭证列表
              this.pagination.page = 1;
              this.getEvidenceDetail();
            })
            .finally(() => {
              this.flag.uploadvouchering = false;
            });
        })
        .catch();
    },
    //凭证列表分页
    paginationChange(e) {
      this.pagination.page = e;
      this.getEvidenceDetail();
    },
    // 五流合一
    // 合同流分页
    handleCurrentChange1(e) {
      this.pagination1.page = e;
      this.contractList = this.detailInfo.Contracts.slice(
        this.pagination1.pagesize * (this.pagination1.page - 1),
        this.pagination1.pagesize * this.pagination1.page
      );
    },
    // 资金流分页
    handleCurrentChange2(e) {
      this.pagination2.page = e;
      this.vouchersList = this.detailInfo.Vouchers.slice(
        this.pagination2.pagesize * (this.pagination2.page - 1),
        this.pagination2.pagesize * this.pagination2.page
      );
    },
    // 发票流分页
    handleCurrentChange3(e) {
      this.pagination3.page = e;
      this.invoicesList = this.detailInfo.Invoices.slice(
        this.pagination3.pagesize * (this.pagination3.page - 1),
        this.pagination3.pagesize * this.pagination3.page
      );
    },
    // 添加凭证URL
    addVouchers() {
      this.flag.addVouchersDialog = true;
      this.vouchersUrls = "";
    },
    // 保存凭证URL
    updateVouchers() {
      let urls = [];
      if (this.vouchersUrls) {
        urls = this.vouchersUrls.split(/,|，/);
      }
      let params = {
        operatorID: "",
        businessID: this.businessID,
        evidenceTypeID: this.flag.voucherTabChildActive,
        urls,
      };
      AddEvidenceByUrls(params).then((res) => {
        this.$message.success("保存成功");
        this.getEvidenceDetail();
        this.flag.addVouchersDialog = false;
      });
    },
    //打开地图
    openMap(item) {
      const loading = this.$loading({
        lock: true,
        text: "轨迹请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        wayBillId: item.WaybillID,
        searchWord: item.CarNumber,
        stDate: item.LoadingDatetime,
        edDate: item.SignDatetime,
        coordType: 2,
      };
      getMapPath({ json: JSON.stringify(params) })
        .then((res) => {
          this.mapList = res.Track;
          this.flag.showMap = true;
        })
        .finally(() => {
          loading.close();
        });
    },
    //预览pdf
    previewPDF(url) {
      // window.open(url);
      const el = document.createElement("a");
      el.style.display = "none";
      el.setAttribute("target", "_blank");
      /**
       * download的属性是HTML5新增的属性
       * href属性的地址必须是非跨域的地址，如果引用的是第三方的网站或者说是前后端分离的项目(调用后台的接口)，这时download就会不起作用。
       * 此时，如果是下载浏览器无法解析的文件，例如.exe,.xlsx..那么浏览器会自动下载，但是如果使用浏览器可以解析的文件，比如.txt,.png,.pdf....浏览器就会采取预览模式
       * 所以，对于.txt,.png,.pdf等的预览功能我们就可以直接不设置download属性(前提是后端响应头的Content-Type: application/octet-stream，如果为application/pdf浏览器则会判断文件为 pdf ，自动执行预览的策略)
       */
      el.setAttribute("download", "");
      el.href = url;
      document.body.appendChild(el);
      el.click();
      document.body.removeChild(el);
    },
    // 显示各种弹框
    // 显示服务商/货主信息弹框
    showSupplyDialog(scope, index) {
      // 如果scope没数据则是五流合一进来的
      if (scope) {
        this.currentItem = scope.row;
      } else {
      }
      this.type = index;
      if (index == 1) {
        // 服务商信息
        if (!this.currentItem.PrincipalCompany) {
          this.$message.warning("暂无服务商信息");
          return;
        }
        this.supplyId = this.currentItem.PrincipalCompany;
      } else if (index == 2) {
        // 货主信息
        if (!this.currentItem.UserID) {
          this.$message.warning("暂无货主信息");
          return;
        }
        this.supplyId = this.currentItem.UserID;
      }
      this.supplyDialog = true;
    },
    // 查询人员信息
    getPersonInfo(item, UserID) {
      const loading = this.$loading({
        lock: true,
        text: "数据请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (item) {
        this.currentItem = item;
      }
      getDriverDetails({ UserID })
        .then((res) => {
          data = Object.assign(
            {},
            res.data.driver_baseInfo,
            res.data.driver_confirmInfo
          );
          this.driverInfo = data;
          this.personDialog = true;
        })
        .finally(() => {
          loading.close();
        });
    },
    // 用于五流合一获取车辆
    showVehicleInfo() {
      let getVehicleInfoed = false; //是否拿到了车子信息
      let getCarInfoed = false; //是否拿到了车子证件照
      let params = {}; // 车辆信息

      getVehicleInfoed = true;
      let params2 = {
        CarNumber: this.currentItem.CarNumber,
      };
      getCarInfo(params2)
        .then((res) => {
          this.vehicleInfo = Object.assign(
            params,
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          getCarInfoed = true;
        })
        .finally(() => {
          if (getVehicleInfoed && getCarInfoed) {
            this.vehicleDialog = true;
          }
        });
    },
    // 表格颜色
    tableRowClassName({ row, rowIndex }) {
      return "blue";
    },
    tableRowClassName1({ row, rowIndex }) {
      return "orange";
    },
    // 处理五流合一数据
    cancleFiveInfo() {
      // 合同列表数据
      this.pagination1.total = this.detailInfo.Contracts.length;
      this.contractList = this.detailInfo.Contracts.slice(
        0,
        this.pagination1.pagesize
      );
      // 资金流列表数据
      this.pagination2.total = this.detailInfo.Vouchers.length;
      this.vouchersList = this.detailInfo.Vouchers.slice(
        0,
        this.pagination2.pagesize
      );

      // 发票流列表数据
      this.pagination3.total = this.detailInfo.Invoices.length;
      this.invoicesList = this.detailInfo.Invoices.slice(
        0,
        this.pagination3.pagesize
      );

      this.detailInfo.WaybillInfo.enterpriseFullName =
        this.currentItem.EnterpriseFullName;
      this.getWaybillInvoice();
    },
    // 获取五流合一发票流
    getWaybillInvoice() {
      let params = {
        pageSize: this.queryParams.pagesize,
        pageIndex: this.queryParams.page,
        waybillID: this.detailInfo.WaybillInfo.WaybillID,
      };
      GetWaybillInvoice(params).then((res) => {
        this.waybillInvoiceList = res.data.DataList || [];
        this.queryParams.total = Number(res.data.TotalCount);
      });
    },
    //分页控件页码change事件回调
    handleWaybillInvoiceChange(e) {
      this.queryParams.page = e;
      this.getWaybillInvoice();
    },
    // 关联凭证
    // 打开关联凭证弹框
    relationVouchers() {
      this.flag.relationVouchersDialog = true;
      this.$nextTick(() => this.$refs.relationVoucher.getVoucherFilesList());
    },
  },
  created() {
    if (this.isShowFiveInfo) {
      this.activeName = "1";
    }
    if (this.isShowDetailInfo) {
      this.activeName = "-1";
    }
    // 处理五流合一信息
    if (this.activeName == "1") {
      this.cancleFiveInfo();
    }
    this.flag.isShowOfflineVoucher = this.isShowOfflineVoucher;
    this.getEvidenceParentType();
  },

  components: {
    SupplyInfo,
    DriverInfo,
    VehicleInfo,
    TXmap,
    BossInfo,
    relationVoucher,
  },
};
</script>
<style lang="scss">
.input-with-select {
  width: 280px;
  margin-right: 10px;

  .el-input-group__prepend {
    background-color: #fff;
  }

  .el-select {
    .el-input {
      width: 90px;
    }
  }
}

.evidence-component {
  .el-dialog__wrapper {
    overflow: hidden;
  }

  .el-dialog__body {
    padding-top: 5px !important;
    max-height: 80vh;
    overflow: auto;
  }
}

.evidence-component {
  .parent-title {
    width: 1150px;
    border-bottom: 1px solid #efefef;

    .title-con {
      width: 90%;
      border: 1px solid #efefef;
      border-bottom: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      text-align: center;
      padding: 8px 0;
      cursor: pointer;
      font-size: 15px;
    }
  }

  .activeTab {
    background: #409eff;
    color: white;
  }

  .hasContent {
    position: relative;
  }

  .hasContent::after {
    content: "";
    position: absolute;
    top: -3px;
    right: -3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #f56c6c;
  }

  .child-title {
    border: 1px solid #efefef;
    text-align: center;
    border-radius: 3px;
    padding: 8px 16px;
    margin: 0 4px;
    cursor: pointer;
    font-size: 15px;
  }

  .voucherWrap {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    width: auto;

    .item {
      width: 19%;
      margin: 16px 0;
      position: relative;

      .delete {
        cursor: pointer;
        font-size: 20px;
        position: absolute;
        bottom: 30px;
        right: 30px;
        z-index: 9999;
      }

      p {
        width: 200px;
        color: blue;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .info-title {
    font-size: 16px;
    font-weight: bold;
    margin: 8px 0px 16px 0px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-left {
      display: flex;
      align-items: center;

      .img {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }

      span {
        font-size: 16px;
      }
    }

    .detail-info {
      .el-link--inner {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .el-link.el-link--default:hover {
    color: #409eff;
  }

  .el-link.el-link--default {
    color: #0f5fff;
  }

  .voucher-link {
    .el-link--inner {
      font-size: 16px;
    }
  }

  .info-con {
    width: 265px;
    font-size: 14px;
    color: #333333;

    .title {
      font-weight: bold;
      margin-bottom: 8px;
    }

    .con {
      width: 265px;
      height: 36px;
      background: #f7f7f7;
      border-radius: 4px;
      border: 1px solid #f7f7f7;
      line-height: 36px;
      text-align: center;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .con1 {
      width: 1130px;
      height: 36px;
      background: #f7f7f7;
      border-radius: 4px;
      border: 1px solid #f7f7f7;
      line-height: 36px;
      text-align: center;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .pagination1 {
    margin-top: 10px;
    background: #ecf2ff;

    li {
      background: #ecf2ff;
    }

    .btn-next {
      background: #ecf2ff;
    }

    .btn-prev {
      background: #ecf2ff;
    }

    button:disabled {
      background: #ecf2ff;
    }
  }

  .pagination2 {
    margin-top: 10px;
    background: #fff9f3;

    li {
      background: #fff9f3;
    }

    .btn-next {
      background: #fff9f3;
    }

    .btn-prev {
      background: #fff9f3;
    }

    button:disabled {
      background: #fff9f3;
    }
  }
}
</style>
