<template>
  <div class="driverWrap">
    <div class="imgList">
      <div class="item" v-if="_driverInfo.DriverCardOnURL">
        <p>身份证正面</p>
        <el-image :src="_driverInfo.DriverCardOnURL" :preview-src-list="[_driverInfo.DriverCardOnURL]"></el-image>
      </div>
      <div class="item" v-if="_driverInfo.DriverCardBackURL">
        <p>身份证反面</p>
        <el-image :src="_driverInfo.DriverCardBackURL" :preview-src-list="[_driverInfo.DriverCardBackURL]"></el-image>
      </div>
      <div class="item" v-if="_driverInfo.DriverLicenseOnURL">
        <p>驾驶证正面</p>
        <el-image :src="_driverInfo.DriverLicenseOnURL" :preview-src-list="[_driverInfo.DriverLicenseOnURL]"></el-image>
      </div>
      <div class="item" v-if="_driverInfo.DriverLicenseBackURL">
        <p>驾驶证反面</p>
        <el-image :src="_driverInfo.DriverLicenseBackURL" :preview-src-list="[_driverInfo.DriverLicenseBackURL]"></el-image>
      </div>
      <div class="item" v-if="_driverInfo.RTQCertificateURL">
        <p>道路经营许可证</p>
        <el-image :src="_driverInfo.RTQCertificateURL" :preview-src-list="[_driverInfo.RTQCertificateURL]"></el-image>
      </div>
      <div class="item" v-if="_driverInfo.BankCardURL">
        <p>银行卡</p>
        <el-image :src="_driverInfo.BankCardURL" :preview-src-list="[_driverInfo.BankCardURL]"></el-image>
      </div>
      <div class="item" v-if="_driverInfo.DriverPhotoURL">
        <p>人车合影</p>
        <el-image :src="_driverInfo.DriverPhotoURL" :preview-src-list="[_driverInfo.DriverPhotoURL]"></el-image>
      </div>
      <div class="item" v-if="_driverInfo.DriverIDCardPhoto">
        <p>手持身份证照</p>
        <el-image :src="_driverInfo.DriverIDCardPhoto" :preview-src-list="[_driverInfo.DriverIDCardPhoto]"></el-image>
      </div>
    </div>
    <el-form class="driverInfo" label-width="130px" :model="_driverInfo">
      <el-form-item label="姓名">
        <el-input v-model="_driverInfo.DriverName" disabled></el-input>
      </el-form-item>
      <el-form-item label="身份证号">
        <el-input v-model="_driverInfo.DriverIDCard" disabled></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="_driverInfo.TelPhone" disabled></el-input>
      </el-form-item>
      <el-form-item label="准驾类型">
        <el-input v-model="_driverInfo.DriverLicenseType" disabled></el-input>
      </el-form-item>
      <el-form-item label="驾驶证起始日期">
        <el-input v-model="_driverInfo.DriverLicenseDateStart" disabled></el-input>
      </el-form-item>
      <el-form-item label="驾驶证有效日期">
        <el-input v-model="_driverInfo.DriverLicenseDateEnd" disabled></el-input>
      </el-form-item>
      <el-form-item label="驾驶证档案号">
        <el-input v-model="_driverInfo.DriverLicenseArchives" disabled></el-input>
      </el-form-item>
      <el-form-item label="驾驶证发证机关">
        <el-input v-model="_driverInfo.DriverLicesenAuthority" disabled></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ['driverInfo'],
  data() {
    return {
    
    }
  },
  computed: {
    _driverInfo() {
      if (!this.driverInfo) return
      let driverInfo = JSON.parse(JSON.stringify(this.driverInfo))
      //年月日时分秒格式保留年月日即可
      driverInfo.DriverLicenseDateStart ? driverInfo.DriverLicenseDateStart = driverInfo.DriverLicenseDateStart.slice(0, 10) : ''
      driverInfo.DriverLicenseDateEnd ? driverInfo.DriverLicenseDateEnd = driverInfo.DriverLicenseDateEnd.slice(0, 10) : ''
      return driverInfo
    }
  }
}
</script>

<style lang="scss" scoped>
.printBtn {
  position: absolute;
  top: 13px;
  left: 90px;
}
.driverInfo {
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    width: 380px;
  }
}
.imgList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .item {
    width: 33%;
    height: 200px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      margin-bottom: 10px;
    }
  }
}
</style>